.navbar {
  width: 100%;
  padding: 0.5rem 2rem;
  position: absolute;
  z-index: 999;
  position: fixed;
  top: 0;
  backdrop-filter: blur(3px);
  left: 0;
}

.navbar_container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  display: flex;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}
.logoMini {
  display: none;
}

/* //-------RIGHT */

.navbar_container_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
}
.navbar_container_right_links {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: var(--font-color-dark);
}
.buttons {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  cursor: pointer;
  align-items: center;
}
.buttons button {
  padding: 0 50px;
}

.navbar_container_right_profile_box {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 10px;
  gap: 1rem;
  border-radius: 5px;
  cursor: pointer;
}
.navbar_container_right_profile {
  border-radius: 50%;
}
.navbar_container_right_profile_name {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: var(--font-color-dark);
  margin: 0;
}
.navbar_container_right_profile_box:hover {
  box-shadow: 0px 0px 20px rgba(151, 71, 255, 0.2);
}

.navbar_container_right_menuBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 5px;
  color: var(--font-color-dark);
}
.navbar_container_right_menuBtn:hover {
  color: var(--icon-color);
}
.navbar_container_right_menuBtn {
  display: none;
}
.navbar_container_right_menuBtn2 {
  width: 60px;
  height: 60px;
  border-radius: 10%;
  color: var(--font-color-dark);
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar_container_right_menuBtn2:hover {
  color: var(--icons-color);
  box-shadow: var(--box-shadow-3);
}

.menuIcon {
  font-size: 2.5rem;
  cursor: pointer;
  color: #ffffff;
}

@media screen and (min-device-width: 320px) and (max-device-width: 960px) {
  .navbar {
    padding: 0.5rem 0rem;
  }
  .navbar_container {
    width: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar_container_right_button button {
    padding: 10px;
    height: 50px;
  }
  .navbar_container_right_profile_name {
    display: none;
  }
  .buttons {
    gap: 0.5rem;
  }
  .lang {
    gap: 5px;
    font-size: 16px;
  }

  .logo {
    display: none;
  }

  .logoMini {
    display: flex;
    align-items: center;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
    padding: 0 1rem;
  }
}

.footer {
  width: 100%;
  padding: 20px;
  position: relative;
  background-color: var(--Mystic);
}
.transparentBg {
  background-color: transparent;
}
.footer_box_social p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #999999;
}

.footer_box {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footer_box_left {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
}

.footer_box_left img {
  width: 174px !important;
}
.footer_box_right {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
}
/* //SOCIAL */
.footer_social {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  align-items: center;
  gap: 2rem;
}

.footer_social a {
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease-in;
  display: grid;
}

.footer_box_down_explore {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.footer_box_down_explore p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0;
}

.footer_box_down_info p {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
@media screen and (max-width: 1024px) {
  .footer {
    padding-bottom: 65px;
  }
}

@media screen and (max-width: 35em) {
  .footer_box {
    gap: 20px;
    flex-direction: column;
  }
  .footer_box_left {
    align-items: center;
    gap: 1rem;
  }
  .footer_box_right {
    align-items: center;
    gap: 1rem;
  }
  .footer_box_down_explore {
    flex-direction: column;
    gap: 2rem;
  }
}

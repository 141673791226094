.heroSection {
  margin: 0 auto 6rem;
  position: relative;
}

.heroSection_box {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 10rem;
  gap: 10rem;
  position: relative;
  z-index: 10;
  background-color: #0000008a;
  padding-top: 70px;
}

.heroSection_box_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  padding: 1rem 4rem 7rem;
}

.heroSection_box_left_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.title {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 200;
  font-size: 145px;
  line-height: 160px;
  text-transform: uppercase;
  color: var(--font-color-dark);
}
.title2 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 145px;
  line-height: 160px;
  text-transform: uppercase;
  color: var(--Amethyst);
}
.title3,
.title4 {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 200;
  font-size: 87px;
  line-height: 160px;
  text-transform: uppercase;
  color: var(--font-color-dark);
}

.title4 {
  position: absolute;
  bottom: -129px;
  right: 20px;
}
.pleasant {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 145px;
  line-height: 100px;
  text-transform: uppercase;
  color: var(--Amethyst);
  display: flex;
  justify-content: flex-end;
}

.heroSection_box_left_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--font-color-dark);

  margin-bottom: 2rem;
  max-width: 650px;
}
.heroSection_box_left > .heroTitle {
  width: 100%;
}
.heroSection_box_left_button {
  background-color: var(--Mystic);
  border: 0;
  color: var(--White);
  cursor: pointer;

  font-family: "Nunito";
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;

  height: 60px;

  padding: 0px 70px;
  transition: all 0.2s ease-in;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.heroSection_box_left_button:hover {
  background-color: var(--Amethyst);
  cursor: pointer;
}

.heroSection_box_left_parr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #999999;
  width: 200px;
}

.heroVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.heroVideo > video {
  width: 100%;
  height: auto;
}

.heroSection_btn {
  display: flex;
  gap: 2rem;
  align-items: center;
}
@media screen and (max-width: 1600px) {
  .heroSection_box {
    grid-template-columns: 9fr 1fr;
  }
  .title,
  .title2 {
    font-size: 120px;
    line-height: 120px;
  }
  .title3,
  .title4 {
    font-size: 80px;
  }
  .pleasant {
    font-size: 120px;
  }
}
@media screen and (max-width: 1440px) {
  .heroSection_box,
  .heroSection_box_video {
    gap: 4rem;
  }
  .heroSection_box_left {
    padding: 5rem 2rem 5rem;
  }
  .heroSection_box_left > p {
    margin: 0;
  }
  .title,
  .title2,
  .title3 {
    font-size: 90px;
    line-height: normal;
  }
  .title3,
  .title4 {
    font-size: 60px;
  }

  .title4 {
    position: static;
    text-align: end;
    line-height: normal;
  }
  .pleasant {
    font-size: 110px;
  }
}

@media screen and (max-width: 1024px) {
  .heroSection_box,
  .heroSection_box_video {
    grid-template-columns: auto;
  }
  .heroSection_box_left {
    padding: 3rem 2rem 3rem;
  }
  .title,
  .title2 {
    font-size: 70px;
    line-height: 70px;
  }
  .title3,
  .title4 {
    font-size: 50px;
    line-height: 70px;
  }
  .pleasant {
    line-height: 50px;
    font-size: 90px;
    line-height: 70px;
  }
  .featuresBrand_box_up {
    height: 250px;
  }

  .featuresBrand_box_up_text {
    top: -25%;
    width: 400px;
    font-size: 17px;
    line-height: 25px;
  }

  .heroVideo > video {
    width: 1000px;
  }
}

@media screen and (max-width: 768px) {
  .heroSection {
    margin: 0 auto 3rem;
  }
  .heroSection_box {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .heroSection_box_left {
    margin-bottom: 4rem;
    padding: 1rem 2rem 3rem;
  }
  .title,
  .title2 {
    font-size: 60px;
    line-height: 60px;
  }
  .title3,
  .title4 {
    font-size: 35px;
    line-height: 30px;
  }
  .title3 {
    display: block;
  }

  .pleasant {
    font-size: 65px;
    line-height: 65px;
  }
  .heroSection_box_left_parr {
    font-size: 17px;
    line-height: 30px;
  }
  .heroSection_box_left div {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
  .heroSection_box_left_button {
    width: 90%;
  }
  .heroVideo > video {
    width: 1200px;
    height: 100%;
    margin-left: calc((100vw - 1200px) / 2);
  }
  .heroSection_box_left_title {
    align-items: center;
    text-align: center;
    gap: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .title,
  .title2 {
    font-size: 50px;
    line-height: 40px;
  }
  .title3,
  .title4 {
    font-size: 25px;
    line-height: 30px;
  }
  .title3 {
    display: block;
  }

  .pleasant {
    font-size: 45px;
    line-height: 40px;
  }
  .heroSection_box_left_parr {
    font-size: 17px;
    line-height: 30px;
  }
  .heroSection_box_left div {
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
}

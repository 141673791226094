.button {
  background-color: var(--Mystic);
  border: 0;
  color: var(--White);
  cursor: pointer;

  font-family: "Nunito";
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;

  width: 100%;
  height: 60px;

  padding: 0px 55px;
  transition: all 0.2s ease-in;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.btnsimple {
  background-color: transparent;
  border: 1px solid #999999;
  color: #999999;
  cursor: pointer;

  font-family: "Nunito";
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;

  width: 100%;
  height: 60px;

  padding: 0px 70px;
  transition: all 0.2s ease-in;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.btnsmall {
  background-color: transparent !important;
  border: 1px solid #999999;
  border-radius: 5px;
  color: #999999;
  cursor: pointer;

  font-family: "Nunito";
  font-weight: 500;
  letter-spacing: 0em;
  font-size: 14px;
  line-height: 25px;

  width: 100%;
  height: auto;

  padding: 10px 16px;
  transition: all 0.2s ease-in;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.btnwhite {
  background-color: #fff;
  color: var(--Amethyst);
  cursor: pointer;

  font-family: "Nunito";
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;

  width: 100%;
  height: 60px;

  padding: 0px 70px;
  transition: all 0.2s ease-in;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button:hover {
  background-color: var(--Amethyst);
  cursor: pointer;
}
.button:hover.hover_action .hover_txt {
  display: block;
}
.button:hover.hover_action .normal_txt {
  display: none;
}
.hover_txt {
  display: none;
}
.button:disabled {
  background-color: var(--Grey);
  cursor: not-allowed;
}

.btnsimple:hover {
  color: #fff;
  background-color: var(--ChineseBlack);
}
.btnwhite:hover {
  color: #000;
  background-color: #fff;
}

.btnsmall:hover {
  color: #fff;
  background-color: var(--ChineseBlack);
}
.box.animated_btn {
  box-shadow: 0 0 0 0 #4e3200;
  animation: 2s btnAnimated linear infinite;
}
.animated_btn button {
  background: linear-gradient(-30deg, #4e3200, #ffcd72);
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  overflow: hidden;
  letter-spacing: 2.5px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  font-size: 26px;
}

.animated_btn button::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #d0962a;
  opacity: 0;
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated_btn button:hover::before {
  opacity: 0.2;
}

.animated_btn button span {
  position: absolute;
}

.animated_btn button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 5px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to left, rgba(43, 8, 8, 0), #312000);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes btnAnimated {
  0% {
    box-shadow: 0 0 10px 9px #312000;
  }
  50% {
    box-shadow: 0 0 20px 9px #a779248f;
  }
  100% {
    box-shadow: 0 0 10px 9px #312000;
  }
}

.animated_btn button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 5px;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to top, rgba(43, 8, 8, 0), #8b600f);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated_btn button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 5px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to right, rgba(43, 8, 8, 0), #8b600f);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated_btn button span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 5px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(43, 8, 8, 0)), to(#d92626));
  background: linear-gradient(to bottom, rgba(43, 8, 8, 0), #8b600f);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@media screen and (max-width: 1600px) {
  .button {
    font-size: 18px;
    padding: 0px 50px;
  }
  .btnsimple {
    font-size: 18px;
    padding: 0px 50px;
  }
  .btnsmall {
    font-size: 12px;
    padding: 12px 16px;
    height: 20px;
  }
}

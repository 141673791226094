.home {
  width: 100%;
  min-height: 100vh;
}
.home_box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
}

.title_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;
}
.title h1 {
  display: flex;
  font-family: "Nunito";
  text-transform: uppercase;
  color: var(--font-color-dark);
  font-size: 1.8rem;
  margin: 0;
}
.title span {
  color: var(--Amethyst);
}
.title p {
  font-family: "Nunito";
  font-weight: 400;
  font-size: 1.8rem;
  text-transform: lowercase;
  color: var(--font-color-dark);
}

@media screen and (max-width: 1024px) {
  .home {
    width: 100%;
    left: 0;
  }
}

@media screen and (max-width: 768px) {
  .title img {
    width: 28px !important;
  }
}

@media screen and (max-width: 576px) {
  .btnView {
    display: none;
  }
  .home {
    padding-top: 80px;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Mulish";
  font-style: normal;
  src: url("../public/fonts/Mulish-VariableFont_wght.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  src: url("../public/fonts/Nunito-VariableFont_wght.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Kodchasan";
  font-style: normal;
  src: url("../public/fonts/Kodchasan-ExtraLight.ttf");
  weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Kodchasan";
  font-style: normal;
  src: url("../public/fonts/Kodchasan-Medium.ttf");
  weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Kodchasan";
  font-style: normal;
  src: url("../public/fonts/Kodchasan-Bold.ttf");
  weight: 700;
  font-display: swap;
}

@layer base {
  :root {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;

    --ring: 240 4.9% 83.9%;

    --Mystic: #69468c;
    --Amethyst: #9966cc;
    --ChineseBlack: #130d1a;
    --ChineseBlack2: #1d1326;
    --ChineseBlack3: #0a060dec;
    --DarkLust: #0a060d;
    --Grey: #999999;
    --White: #ffffff;
    --WhiteEndless: #f2f2f2;
    --White-bone: #1d1326;
    --Mystic-opacity-25: rgba(105, 70, 140, 0.6);
    --Mystic-opacity-10: rgba(105, 70, 140, 0.9);
    --font-color-dark: var(--White);
    --font-color-light: var(--ChineseBlack2);
    --font-color-black: #999999;
    --font-color-white: #fff;

    --icons-color: var(--Amethyst);
    --icons-light-color: #4c577341;
    --icons-bg-color: #e2e6e9;
    --shadow-dark-color: #d3dae7;
    --shadow-light-color: #fff;
    --main-bg-color: #0a060d;
    --main-bg-color2: var(--ChineseBlack2);
    --main-bg-blur: rgba(19, 13, 26, 0.7);

    --box-shadow: 1px 1px 2px #9001f5, -1px -1px 2px #9001f5;
    --box-shadow-2: 1px 1px 2px #9001f5, -1px -1px 2px #9001f5;
    --box-shadow-3: 0px 0px 20px rgba(151, 71, 255, 0.2);
    --box-shadow-4: 0px 0px 30px 0px rgba(153, 102, 204, 0.3);

    --error-text-color: #ff5e5e;
    --error-line-color: red;
  }
  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;

    --ring: 240 4.9% 83.9%;

    --Mystic: #69468c;
    --Amethyst: #9966cc;
    --ChineseBlack: #130d1a;
    --ChineseBlack2: #1d1326;
    --ChineseBlack3: #0a060dec;
    --DarkLust: #0a060d;
    --Grey: #999999;
    --White: #ffffff;
    --WhiteEndless: #f2f2f2;
    --White-bone: #1d1326;
    --Mystic-opacity-25: rgba(105, 70, 140, 0.6);
    --Mystic-opacity-10: rgba(105, 70, 140, 0.9);
    --font-color-dark: var(--White);
    --font-color-light: var(--ChineseBlack2);
    --font-color-black: #999999;
    --font-color-white: #fff;

    --icons-color: var(--Amethyst);
    --icons-light-color: #4c577341;
    --icons-bg-color: #e2e6e9;
    --shadow-dark-color: #d3dae7;
    --shadow-light-color: #fff;
    --main-bg-color: #0a060d;
    --main-bg-color2: var(--ChineseBlack2);
    --main-bg-blur: rgba(19, 13, 26, 0.7);

    --box-shadow: 1px 1px 2px #9001f5, -1px -1px 2px #9001f5;
    --box-shadow-2: 1px 1px 2px #9001f5, -1px -1px 2px #9001f5;
    --box-shadow-3: 0px 0px 20px rgba(151, 71, 255, 0.2);
    --box-shadow-4: 0px 0px 30px 0px rgba(153, 102, 204, 0.3);

    --error-text-color: #ff5e5e;
    --error-line-color: red;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: none;
}

.slick-list,
.slick-slider,
.slick-track {
  padding: 1.5rem 0;
}

.AuthorMultimediaCard {
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: 5px 0;
  width: 380px;
  height: 214px;
  background-color: var(--main-bg-color2);
}

.center .slick-center .AuthorMultimediaCard {
  transform: scale(1.3);
  z-index: 10000;
  transition: all 400ms ease-in-out;
  pointer-events: all;
  opacity: 1;
}

.center .AuthorMultimediaCard {
  opacity: 0.7;
  pointer-events: none;
}

.AuthorMultimediaCard_icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--icons-color);
  font-size: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.AuthorMultimediaCard:hover .AuthorMultimediaCard_icon {
  transition: all 400ms ease-in-out;
  display: none;
}

.AuthorMultimediaCard_fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  font-size: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  transition: all 400ms ease-in-out;
}

.AuthorMultimediaCard:hover .AuthorMultimediaCard_fullscreen {
  display: flex;
}

@media (max-width: 990px) {
  .center .slick-center .AuthorMultimediaCard {
    transform: scale(1);
  }

  .center .AuthorMultimediaCard {
    opacity: 1;
    pointer-events: all;
  }
}
::selection {
  color: var(--shadow-dark-color);
  background: var(--icons-color);
}

::-webkit-scrollbar {
  width: 0.7rem;
  border: 1px solid var(--main-bg-color);
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: var(--main-bg-color);
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: var(--icons-color);
  width: 0.5rem;
}

/* Typography */

h1.h1 {
  font-size: 48px;
  font-weight: bold;
}

h2.h2 {
  /* H2 */
  font-family: Nunito;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h3.h3 {
  /* H3 */
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
p.title-small {
  /* Title Small */
  font-family: Mulish;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
p.title-large {
  /* Title Large */
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
p.body {
  /* Title Large */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--WhiteEndless);
}

p.brand-text {
  /* Brand Text */
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

p.cta {
  /* CTA */
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Color */
.text_white {
  color: var(--White) !important;
}
.text_purple {
  color: var(--Amethyst) !important;
}
/* Align */
.text_center {
  text-align: center;
}

/* Swal Alert */
.swal2-toast-purple-border {
  border: 1px solid var(--Amethyst);
  color: var(--WhiteEndless);
}
.swal2-timer-progress-purple {
  background-color: var(--Grey);
}
.swal2-loader {
  border-color: var(--Amethyst) rgba(0, 0, 0, 0) var(--Amethyst)
    rgba(0, 0, 0, 0);
}

div:where(.swal2-container) input:where(.swal2-input):focus,
div:where(.swal2-container) input:where(.swal2-file):focus,
div:where(.swal2-container) textarea:where(.swal2-textarea):focus {
  border: 1px solid var(--Amethyst) !important;
  box-shadow: inset 0 1px 1px var(--Mystic-opacity-25),
    0 0 0 3px var(--Mystic-opacity-25) !important;
}

.swal2-popup.swal2-toast .swal2-actions {
  justify-content: center !important;
}

.swal2-popup.swal2-toast .swal2-title {
  text-align: center !important;
}

/* Cambia el color del fondo de los puntos de la paginación */
.swiper-pagination-bullet {
  background-color: var(--White) !important;
}

/* Cambia el color del punto activo (el slide actual) */
.swiper-pagination-bullet-active {
  background-color: var(--Amethyst) !important;
}
.swiper-button-prev,
.swiper-button-next {
  color: var(--Amethyst) !important;
}
/* Audio */
.rhap_container {
  background-color: var(--DarkLust) !important;
  box-shadow: inset 0px 0px 5px 0px var(--Amethyst) !important;
}
.rhap_time {
  color: #ffffff !important;
}

.rhap_main-controls-button {
  color: #ffffff !important;
}
.container-gallery .action-delete {
  display: none;
}

.container-gallery .section-img-preview > img,
.container-gallery .section-img-preview > video {
  max-width: 250px;
  max-height: 250px;
}

/* Responsive */

.row {
  display: flex;
  flex-wrap: wrap;
}
.align-center {
  align-items: center;
}
.gap-1 {
  gap: 1rem;
}
.gap-2 {
  gap: 2rem;
}
.col,
.col-12 {
  flex-basis: 100%;
}
.col-10 {
  flex-basis: 83.6%;
}
.col-9 {
  flex-basis: 75%;
}
.col-8 {
  flex-basis: 66.6%;
}
.col-7 {
  flex-basis: 58.33%;
}
.col-6 {
  flex-basis: 50%;
}
.col-5 {
  flex-basis: 41.66%;
}
.col-4 {
  flex-basis: 33.3%;
}
.col-3 {
  flex-basis: 25%;
}
.col-2 {
  flex-basis: 16.66%;
}
.col-1 {
  flex-basis: 16.66%;
}

@media screen and (max-width: 1440px) {
  .AuthorMultimediaCard {
    width: 290px;
    height: 163px;
  }

  .col-xl-12 {
    flex-basis: 100%;
  }
  .col-xl-8 {
    flex-basis: 66.6%;
  }
  .col-xl-6 {
    flex-basis: 50%;
  }
  .col-xl-4 {
    flex-basis: 33.3%;
  }
  .col-xl-3 {
    flex-basis: 25%;
  }
  .col-xl-2 {
    flex-basis: 16.66%;
  }
  .col-xl-1 {
    flex-basis: 16.66%;
  }
}

@media screen and (max-width: 1200px) {
  .AuthorMultimediaCard {
    width: 230px;
    height: 129px;
  }
}

@media screen and (max-width: 1024px) {
  .AuthorMultimediaCard {
    width: 250px;
    height: 140px;
  }

  .col-l-12 {
    flex-basis: 100%;
  }
  .col-l-8 {
    flex-basis: 66.6%;
  }
  .col-l-6 {
    flex-basis: 50%;
  }
  .col-l-4 {
    flex-basis: 33.3%;
  }
  .col-l-3 {
    flex-basis: 25%;
  }
  .col-l-2 {
    flex-basis: 16.66%;
  }
  .col-l-1 {
    flex-basis: 16.66%;
  }
}
@media screen and (max-width: 768px) {
  .AuthorMultimediaCard {
    width: 230px;
    height: 130px;
  }

  .col-m-12 {
    flex-basis: 100%;
  }
  .col-m-8 {
    flex-basis: 66.6%;
  }
  .col-m-6 {
    flex-basis: 50%;
  }
  .col-m-4 {
    flex-basis: 33.3%;
  }
  .col-m-3 {
    flex-basis: 25%;
  }
  .col-m-2 {
    flex-basis: 16.66%;
  }
  .col-m-1 {
    flex-basis: 16.66%;
  }
}
@media screen and (max-width: 576px) {
  .col-s-12 {
    flex-basis: 100%;
  }
  .col-s-8 {
    flex-basis: 66.6%;
  }
  .col-s-6 {
    flex-basis: 50%;
  }
  .col-s-4 {
    flex-basis: 33.3%;
  }
  .col-s-3 {
    flex-basis: 25%;
  }
  .col-s-2 {
    flex-basis: 16.66%;
  }
  .col-s-1 {
    flex-basis: 16.66%;
  }

  .container-gallery .section-img-preview > img,
  .container-gallery .section-img-preview > video {
    max-width: 150px;
    max-height: 150px;
  }
}
@media screen and (max-width: 375px) {
  .AuthorMultimediaCard {
    width: 300px;
    height: 170px;
  }

  .col-xs-12 {
    flex-basis: 100%;
  }
  .col-xs-8 {
    flex-basis: 66.6%;
  }
  .col-xs-6 {
    flex-basis: 50%;
  }
  .col-xs-4 {
    flex-basis: 33.3%;
  }
  .col-xs-3 {
    flex-basis: 25%;
  }
  .col-xs-2 {
    flex-basis: 16.66%;
  }
  .col-xs-1 {
    flex-basis: 16.66%;
  }
}

@keyframes show-modal {
  80% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(1.1);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes hidde-modal {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.6);
  }
}
